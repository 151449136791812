body {
  background-color: #23252C;
  display: flex;
  justify-content: center;
  margin: 50px;
}

.App {
  background-color: #1A1B21;
  width: 195px;
  margin: 0;
  padding: 0;

}

.info {
  background-color: #1A1B21;
  border-radius: 10px 10px 0px 0px;
  width: 195px;

  height: auto;
}
.foto {
  border-radius: 10px 10px 0px 0px;
  background-color: #23252C;
}
.sobre {
  padding-left: 10px;
  background-color: #1A1B21;
}
.interesse {
  padding-left: 10px;

}
.nome {
  text-align: center;
  color:#FFFFFF;
}
.stack {
  text-align: center;
  color: #F3BF99;
}
.botoes {
  display: flex;
  justify-content: center;
}
.botao {
  text-align: center;
  display: inline-block;
  width: 50px;
  padding: 5px 0;
  background: #5093E2;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
}
a {
  text-decoration: none;
}
h1 {
  font-size: 17px;
}
h2 {
  font-size: 14px;
  color:#FFFFFF;

}
h3 { 
  font-size: 12px;
  color:#FFFFFF;

}
p {
  color:#FFFFFF;
  font-size: 11px;
}
.footer {
  background-color: #161619;
  display: flex;
  justify-content: center;
}

.icone {
  margin: 10px ;
}

